/*eslint sort-keys: "error"*/
export default {
  'account.email.already_confirmed': 'Deine E-Mail wurde bereits bestätigt',
  'account.email.confirmed': 'Deine E-Mail wurde erfolgreich bestätigt',
  'account.email.not_confirmed': 'Deine E-Mail wurde nicht bestätigt',
  'account.not_complete_profile_button': 'Jetzt Daten vervollständigen',
  'account.not_complete_profile_text':
    'In deinem Profil fehlen noch verschiedene wichtige Angaben wie deine Anschrift, Aktionserfahrungen, Arbeitsvorlieben und auch ein Bild. Diese Angaben sind für die Beurteilung deiner Bewerbung wichtig und erhöhen deine Job-Chancen!',
  'account.notification.missing_data':
    'Kommunikationsdaten und Adresse muss gegeben sein',
  'account.notification.public_profile_disabled':
    'Änderung gespeichert: Profil ist nicht öffentlich sichtbar',
  'account.notification.public_profile_enabled':
    'Änderung gespeichert: Profil ist nun öffentlich sichtbar',
  'account.notification.searchable_profile_disabled':
    'Änderung gespeichert: Profil ist nicht für Jobanbieter such- und sichtbar',
  'account.notification.searchable_profile_enabled':
    'Änderung gespeichert: Profil ist nun für Jobanbieter such- und sichtbar',
  'account.notification.settings_saved':
    'Die Einstellungen wurden erfolgreich gespeichert',

  'account.settings.publicProfile': 'Öffentlich sichtbare Sedcard',
  'account.settings.searchableProfile': 'Für die Suche freischalten',
  'account.settings.subscribeJobletter': 'Zusendung von JobLetter bestätigen',
  'account.settings.subscribeNewsletter':
    'Zusendung von Newslettern bestätigen',
  'account.settings.subscribePush': 'Zusendung von Push-Nachrichten bestätigen',
  'account.settings.subscribeSms': 'Zusendung von SMS-Nachrichten bestätigen',
  'account.verify_user': 'E-Mail erneut senden',
  'account.verify_user_email_already_sent':
    'E-Mail wurde bereits gesendet, bitte überprüfe deinen Posteingang',
  'account.verify_user_email_sent':
    'E-Mail zur Account-Verifizierung wurde erfolgreich versendet',
  'account.verify_user_text':
    'Dein Konto wurde noch nicht verifiziert, d.h. die E-Mail-Adresse noch nicht bestätigt. \n Klicke auf den Button um Dir die E-Mail zur Bestätigung erneut zuzusenden.',
  'account.verify_user_text_apply':
    'Konto wurde noch nicht verifiziert, d.h. die E-Mail-Adresse noch nicht bestätigt. \n Klicke auf den Button um Dir die E-Mail zur Bestätigung erneut zuzusenden.',
  'account.verify_user_text_update_and_apply':
    'Dein Konto wurde noch nicht verifiziert, d.h. die E-Mail-Adresse noch nicht bestätigt. \n Klicke auf den Button um Dir die E-Mail zur Bestätigung erneut zuzusenden.',

  'action.apply_with': 'Mit {channel} anmelden',
  'action.apply_with_apple': 'Apple',
  'action.apply_with_facebook': 'Facebook',
  'action.apply_with_google': 'Google',
  'action.apply_with_microsoft': 'Microsoft',
  'action.apply_with_pb': 'Mit Promotionbasis anmelden',
  'action.candidate': 'Jobsuchender',
  'action.confirm_delete': 'Schreibe Wort: delete',
  'action.favourites': 'Favoriten',
  'action.job_detail_page': 'Zur Jobansicht',
  'action.job_seekers': 'Jobsuchender',
  'action.login': 'Login',
  'action.login_apple': 'Apple',
  'action.login_buttons': 'Anmeldung mit',
  'action.login_error': 'Es ist ein Fehler aufgetreten!',
  'action.login_error_401': 'Nutzername/Passwort falsch!',
  'action.login_facebook': 'Facebook',
  'action.login_google': 'Google',
  'action.login_microsoft': 'Microsoft',
  'action.logout': 'Logout',
  'action.more': 'Mehr',
  'action.organization': 'Jobanbieter',
  'action.register': 'Registrieren',
  'action.registration': 'Registrierung',
  'action.registration_before_application_languages_error':
    'Sie müssen mindestens eine Sprache eingeben',
  'action.registration_before_application_login_button_text':
    'Weiter zur Bewerbung',
  'action.registration_before_application_previous': 'Zurück',
  'action.registration_before_application_registration_button_text':
    'Weiter zur Bewerbung',
  'action.registration_or_login_reg_error_org_email':
    'E-Mail wird bereits von einem Jobanbieter verwendet',
  'action.registration_or_login_reg_success':
    'Super! Deine Anmeldung wurde erfolgreich gespeichert!',
  'action.search': 'Suche',
  'action.signin': 'Anmelden',
  'action.social_icons': 'Die Aktion wurde erfolgreich abgeschlossen',
  'action.social_icons_close': 'Schließen',
  'action.user_delete':
    'Die Löschung deines Accounts wurde erfolgreich hinterlegt. Du wirst per E-Mail über die endgültige Löschung deiner Daten informiert.',
  'action.user_login': 'Erfolgreich eingeloggt! ',
  'action.user_logout': 'Erfolgreich ausgeloggt!',
  'action.user_not_logged': 'Du bist nicht eingeloggt!',

  'activity.actor': 'Schauspieler(in) / Kompars(in)',
  'activity.animation': 'Animateur(in)',
  'activity.aufbauhelfer': 'Aufbauhelfer',
  'activity.barkeeper': 'Barista / Barkeeper',
  'activity.buchhaltung': 'Buchhaltung / Controlling',
  'activity.callcenter': 'CallCenter-Agent',
  'activity.coach': 'Coach / Trainer(in)',
  'activity.coaching': 'Coaching / Training',
  'activity.conception': 'Konzeption / Beratung',
  'activity.construction': 'Auf- / Umbauten',
  'activity.cook': 'Koch / Köchin',
  'activity.deko': 'Dekorateur',
  'activity.dj': 'DJ',
  'activity.entwicklung': 'IT / Web-Entwicklung',
  'activity.eventsupervisor': 'Veranstaltungsbetreuer(in) / -reiniger(in)',
  'activity.eventsupport': 'Veranstaltungsbetreuung / -reinigung',
  'activity.fachberatung': 'Fachberater / Sales-Promotion',
  'activity.fahrer': 'Fahrer',
  'activity.fieldwork': 'Außendienstler',
  'activity.flyerverteiler': 'Flyerverteiler(in)',
  'activity.fundraising': 'Fundraising',
  'activity.gastronomie': 'Gastronomie',
  'activity.grafik': 'Mediengestaltung / Grafik',
  'activity.guerilla': 'Guerilla-Marketing',
  'activity.helpinghand': 'Helping Hand',
  'activity.influencer': 'Influencer(in)',
  'activity.influencermarketing': 'Influencer-Marketing',
  'activity.interpreter': 'Dolmetscher(in)',
  'activity.interviewer': 'Interviewer(in)',
  'activity.interviews': 'Interviews / Studien',
  'activity.komparse': 'Komparse',
  'activity.kosmetik': 'Kosmetiker(in)',
  'activity.kundenbetreuung': 'Kundenbetreuung / Account Management',
  'activity.leasing': 'Leasing-Außendienst',
  'activity.leitung': 'Leitung / Geschäftsführung',
  'activity.lichtdouble': 'Lichtdouble',
  'activity.liveconsulting': 'Live Consulting',
  'activity.logistician': 'Logistiker(in)',
  'activity.market_research': 'Marktforschung',
  'activity.marketing': 'Marketing',
  'activity.media_it': 'IT-Dienstleistungen',
  'activity.mediaconsultant': 'Online Media Berater(in)',
  'activity.mediadesigner': 'Mediendesigner(in)',
  'activity.merchandising': 'Merchandising',
  'activity.messehost': 'Host(ess) / Messehost(ess)',
  'activity.misc': 'Sonstiges / Diverse',
  'activity.misc_various': 'Sonstiges / Diverse',
  'activity.model': 'Model / Fotomodel',
  'activity.moderation': 'Moderator(in)',
  'activity.musician': 'Musiker(in)',
  'activity.mystery_shopping': 'Mystery-Shopping',
  'activity.online_marketing': 'Online-Marketing / SEO / SEA',
  'activity.onlinemediaconsulting': 'Online Media Beratung',
  'activity.personalmanagement': 'Personalmanagement / HR',
  'activity.productpresenter': 'Produktpräsenter(in)',
  'activity.projektmanagement': 'Projektmanagement',
  'activity.rackjobber': 'Rackjobber(in)',
  'activity.reception': 'Empfangsbetreuer(in)',
  'activity.recruitment': 'Personalvermittlung / -dienstleistung',
  'activity.redaktion': 'Content / Text / Redaktion / PR',
  'activity.roadshow': 'Roadshows',
  'activity.salesperson': 'Verkäufer(in)',
  'activity.sampling': 'Sampling',
  'activity.security': 'Security / Sicherheit',
  'activity.sekreteriat': 'Sekreteriat / Empfang',
  'activity.social_media': 'Social Media Management',
  'activity.socialmediamarketing': 'Social Media Marketing',
  'activity.stagehand': 'Stagehand',
  'activity.supervisor': 'Supervisor',
  'activity.tradefairconception': 'Messe- / Eventkonzeption / Messebauer(in)',
  'activity.various': 'Diverse',
  'activity.verkostung': 'Verkostung / Verkoster(in)',
  'activity.vertrieb': 'Vertrieb / Sales',
  'activity.verwaltung': 'Verwaltung / Back-Office',
  'activity.viphost': 'VIP-Host(ess)',
  'activity.waiter': 'Servicekraft',
  'activity.walking_act': 'Kostümträger(in) / Walking Act',
  'activity.warehouse': 'Lagerist(in)',
  'activity.webdesign': 'Webdesign / Webdesigner(in)',

  all_articles: 'Alle Artikel',
  all_jobs: 'Alle Jobs',

  'application.default_link_text': 'Standard-Bewerbungstext einfügen',
  'application.default_text':
    'Mit einem individuellen Bewerbungstext hebst du dich von Mitbewerbern ab und steigerst deine Chancen auf eine Jobzusage! Hier',
  'application.default_text_exists': 'Text ist bereits vorhanden',
  'application.error.already_applied':
    'Du hast dich bereits auf dieses Inserat beworben!',
  'application.error.job_posting_inactive': 'Inserat ist nicht mehr aktiv!',
  'application.error.not_all_relevant_data':
    'Deine Profil-Daten sind unvollständig!',
  'application.error.not_verified':
    'Deine E-Mail Adresse ist noch nicht verifiziert!',
  'application.notes': 'Bewerbungstext / Anschreiben',

  'apply_now.text_logged':
    'Du willst auch mit dabei sein? Klick auf den Button und bewirb dich jetzt!',
  'apply_now.text_not_logged':
    'Du willst auch mit dabei sein? Dann hinterlege schnell und einfach deine Bewerbung!',
  'apply_now.title': 'Jetzt bewerben',

  'article.back_to_overview': 'Zurück zu den Artikeln',
  'article.category_overview':
    'Artikel-Übersicht für Kategorie {category_title}',
  'article.current_articles': 'Aktuelle Beiträge',
  'article.overview': 'Artikel-Übersicht',
  'article.page_title': 'Tipps & Hilfe',
  'article.related_articles': 'Verknüpfte Artikel',
  'article.show_more': 'Mehr lesen',
  'article.teasered_articles': 'Tipps der Redaktion',

  'byll.account.created': 'Byll-Konto erfolgreich erstellt',
  'byll.account.email.not.verify':
    'Für die Erstellung eines Byll-Accounts muss deine E-Mail-Adresse bestätigt sein!',
  'byll.account.error': 'Byll-Konto kann nicht geöffnet werden',
  'byll.account.exists': 'Byll-Konto existiert bereits',

  'carousel.activity_label': 'Tätigkeit wählen',
  'carousel.city_label': 'Stadt wählen',
  'carousel.see_all': 'Alle anzeigen',

  'confirmation.error': 'Gib das Wort "delete" ein',
  'confirmation.required': 'Bestätigung erforderlich',

  'cookies.details': 'Details',
  'cookies.settings': 'Cookie-Einstellungen',

  'country.ad': 'Andorra',
  'country.ae': 'Vereinigte Arabische Emirate',
  'country.af': 'Afghanistan',
  'country.ag': 'Antigua und Barbuda',
  'country.al': 'Albanien',
  'country.am': 'Armenien',
  'country.ao': 'Angola',
  'country.ar': 'Argentinien',
  'country.at': 'Österreich',
  'country.au': 'Australien',
  'country.az': 'Aserbaidschan',
  'country.ba': 'Bosnien und Herzegowina',
  'country.bb': 'Barbados',
  'country.bd': 'Bangladesch',
  'country.be': 'Belgien',
  'country.bf': 'Burkina Faso',
  'country.bg': 'Bulgarien',
  'country.bh': 'Bahrain',
  'country.bi': 'Burundi',
  'country.bj': 'Benin',
  'country.bn': 'Brunei Darussalam',
  'country.bo': 'Bolivien',
  'country.br': 'Brasilien',
  'country.bs': 'Bahamas',
  'country.bt': 'Bhutan',
  'country.bw': 'Botsuana',
  'country.by': 'Belarus',
  'country.bz': 'Belize',
  'country.ca': 'Kanada',
  'country.cd': 'Kongo, Demokratische Republik',
  'country.cf': 'Zentralafrikanische Republik',
  'country.cg': 'Kongo',
  'country.ch': 'Schweiz',
  'country.ci': 'Côte d´Ivoire',
  'country.ck': 'Cookinseln',
  'country.cl': 'Chile',
  'country.cm': 'Kamerun',
  'country.cn': 'China',
  'country.co': 'Kolumbien',
  'country.cr': 'Costa Rica',
  'country.cu': 'Kuba',
  'country.cv': 'Kap Verde',
  'country.cy': 'Zypern',
  'country.cz': 'Tschechische Republik',
  'country.de': 'Deutschland',
  'country.dj': 'Dschibuti',
  'country.dk': 'Dänemark',
  'country.dm': 'Dominica',
  'country.do': 'Dominikanische Republik',
  'country.dz': 'Algerien',
  'country.ec': 'Ecuador',
  'country.ee': 'Estland',
  'country.eg': 'Ägypten',
  'country.er': 'Eritrea',
  'country.es': 'Spanien',
  'country.et': 'Äthiopien',
  'country.fi': 'Finnland',
  'country.fj': 'Fidschi',
  'country.fm': 'Mikronesien',
  'country.fr': 'Frankreich',
  'country.ga': 'Gabun',
  'country.gb': 'Großbritannien',
  'country.gd': 'Grenada',
  'country.ge': 'Georgien',
  'country.gh': 'Ghana',
  'country.gm': 'Gambia',
  'country.gn': 'Guinea',
  'country.gq': 'Äquatorialguinea',
  'country.gr': 'Griechenland',
  'country.gt': 'Guatemala',
  'country.gw': 'Guinea-Bissau',
  'country.gy': 'Guyana',
  'country.hk': 'Hong Kong',
  'country.hn': 'Honduras',
  'country.hr': 'Kroatien',
  'country.ht': 'Haiti',
  'country.hu': 'Ungarn',
  'country.id': 'Indonesien',
  'country.ie': 'Irland',
  'country.il': 'Israel',
  'country.in': 'Indien',
  'country.iq': 'Irak',
  'country.ir': 'Iran',
  'country.is': 'Island',
  'country.it': 'Italien',
  'country.jm': 'Jamaika',
  'country.jo': 'Jordanien',
  'country.jp': 'Japan',
  'country.ke': 'Kenia',
  'country.kg': 'Kirgisistan',
  'country.kh': 'Kambodscha',
  'country.ki': 'Kiribati',
  'country.km': 'Komoren',
  'country.kn': 'St. Kitts und Nevis',
  'country.kp': 'Korea, Demokratische Volksrepublik',
  'country.kr': 'Korea, Republik',
  'country.kw': 'Kuwait',
  'country.kz': 'Kasachstan',
  'country.la': 'Laos',
  'country.lb': 'Libanon',
  'country.lc': 'St. Lucia',
  'country.li': 'Liechtenstein',
  'country.lk': 'Sri Lanka',
  'country.lr': 'Liberia',
  'country.ls': 'Lesotho',
  'country.lt': 'Litauen',
  'country.lu': 'Luxemburg',
  'country.lv': 'Lettland',
  'country.ly': 'Libyen',
  'country.ma': 'Marokko',
  'country.mc': 'Monaco',
  'country.md': 'Moldau',
  'country.me': 'Montenegro',
  'country.mg': 'Madagaskar',
  'country.mh': 'Marshallinseln',
  'country.mk': 'Mazedonien',
  'country.ml': 'Mali',
  'country.mm': 'Myanmar',
  'country.mn': 'Mongolei',
  'country.mr': 'Mauretanien',
  'country.mt': 'Malta',
  'country.mu': 'Mauritius',
  'country.mv': 'Malediven',
  'country.mw': 'Malawi',
  'country.mx': 'Mexiko',
  'country.my': 'Malaysia',
  'country.mz': 'Mosambik',
  'country.na': 'Namibia',
  'country.ne': 'Niger',
  'country.ng': 'Nigeria',
  'country.ni': 'Nicaragua',
  'country.nl': 'Niederlande',
  'country.no': 'Norwegen',
  'country.np': 'Nepal',
  'country.nr': 'Nauru',
  'country.nz': 'Neuseeland',
  'country.om': 'Oman',
  'country.pa': 'Panama',
  'country.pe': 'Peru',
  'country.pg': 'Papua-Neuguinea',
  'country.ph': 'Philippinen',
  'country.pk': 'Pakistan',
  'country.pl': 'Polen',
  'country.pt': 'Portugal',
  'country.pw': 'Palau',
  'country.py': 'Paraguay',
  'country.qa': 'Katar',
  'country.ro': 'Rumänien',
  'country.rs': 'Serbien',
  'country.ru': 'Russische Föderation',
  'country.rw': 'Ruanda',
  'country.sa': 'Saudi-Arabien',
  'country.sb': 'Salomonen',
  'country.sc': 'Seychellen',
  'country.sd': 'Sudan',
  'country.se': 'Schweden',
  'country.sg': 'Singapur',
  'country.si': 'Slowenien',
  'country.sk': 'Slowakei',
  'country.sl': 'Sierra Leone',
  'country.sm': 'San Marino',
  'country.sn': 'Senegal',
  'country.so': 'Somalia',
  'country.sr': 'Suriname',
  'country.st': 'São Tomé und Príncipe',
  'country.sv': 'El Salvador',
  'country.sy': 'Syrien',
  'country.sz': 'Swasiland',
  'country.td': 'Tschad',
  'country.tg': 'Togo',
  'country.th': 'Thailand',
  'country.tj': 'Tadschikistan',
  'country.tl': 'Timor-Leste',
  'country.tm': 'Turkmenistan',
  'country.tn': 'Tunesien',
  'country.to': 'Tonga',
  'country.tr': 'Türkei',
  'country.tt': 'Trinidad und Tobago',
  'country.tv': 'Tuvalu',
  'country.tw': 'Taiwan',
  'country.tz': 'Tansania',
  'country.ua': 'Ukraine',
  'country.ug': 'Uganda',
  'country.us': 'Vereinigte Staaten',
  'country.uy': 'Uruguay',
  'country.uz': 'Usbekistan',
  'country.va': 'Vatikanstadt',
  'country.vc': 'St. Vincent und die Grenadinen',
  'country.ve': 'Venezuela',
  'country.vn': 'Vietnam',
  'country.vu': 'Vanuatu',
  'country.ws': 'Samoa',
  'country.ye': 'Jemen',
  'country.za': 'Südafrika',
  'country.zm': 'Sambia',
  'country.zw': 'Simbabwe',

  'dashboard.back_to': 'Zurück zum Dashboard',
  'dashboard.created': 'Bei PB seit',
  'dashboard.last_data_update': 'Letztes Daten-Update',
  'dashboard.last_image_update': 'Letztes Bilder-Update',
  'dashboard.last_login': 'Letzer Login',
  'dashboard.num_applications': 'Anzahl Bewerbungen',
  'dashboard.num_favorites': 'Anzahl Favoriten',
  'dashboard.num_logins': 'Anzahl Logins',
  'dashboard.pbcoins_current_month': 'PB-Coins aktueller Monat',
  'dashboard.pbcoins_last_month': 'PB-Coins letzter Monat',
  'dashboard.pbcoins_total': 'PB-Coins gesamt',

  'degree.isced_2': 'Grund- / Hauptschulabschluss',
  'degree.isced_24': 'Realschulabschluss / Mittlere Reife',
  'degree.isced_3': 'Abitur / Fachhochschul- oder Hochschulreife',
  'degree.isced_5': 'Abgeschlossene Ausbildung / Berufsausbildung',
  'degree.isced_6': 'Bachelor / Diplom FH',
  'degree.isced_7': 'Master / Diplom Universität',
  'degree.isced_8': 'Promotion',
  'degree.isced_9': 'Andere Klassifizierung',
  'degree.isced_none': 'Kein Schulabschluss',

  'denomination.ak': 'alt-katholisch',
  'denomination.ap': 'apostolisch',
  'denomination.ba': 'baptistisch',
  'denomination.bd': 'buddhistisch',
  'denomination.ca': 'calvinistisch',
  'denomination.ce': 'anglikanisch (Church of England)',
  'denomination.di': 'Dissident',
  'denomination.ev': 'evangelisch',
  'denomination.fa': 'freie Religionsgemeinschaft Alzey',
  'denomination.fb': 'freireligiöse Landesgemeinde Baden',
  'denomination.fm': 'freireligiöse Gemeinde Mainz',
  'denomination.fo': 'freireligiöse Gemeinde Offenbach/Main',
  'denomination.fp': 'freireligiöse Gemeinde Pfalz',
  'denomination.fr': 'französisch-reformiert',
  'denomination.gf': 'Quäker',
  'denomination.go': 'griechisch-orthodox',
  'denomination.ht': 'Heilige der letzten Tage',
  'denomination.ib': 'israelitische Religionsgemeinschaft Baden',
  'denomination.if': 'israelitische Kultussteuer Frankfurt',
  'denomination.il': 'israelitische Kultussteuer Land',
  'denomination.is': 'islamisch',
  'denomination.iw': 'israelitische Religionsgemeinschaft Württemberg',
  'denomination.jd': 'jüdisch',
  'denomination.ka': 'katholisch',
  'denomination.lt': 'evangelisch-lutherisch',
  'denomination.lu': 'lutherisch',
  'denomination.me': 'mennonitisch',
  'denomination.ml': 'moslemisch',
  'denomination.mt': 'methodistisch',
  'denomination.na': 'neuapostolisch',
  'denomination.ob': 'ohne Bekenntnis',
  'denomination.ox': 'orthodox',
  'denomination.pr': 'presbyterianisch',
  'denomination.rf': 'evangelisch-reformiert',
  'denomination.rk': 'römisch-katholisch',
  'denomination.ro': 'russisch-orthodox',
  'denomination.so': 'sonstige',
  'denomination.un': 'evangelisch-uniert / freie Protestanten',
  'denomination.wr': 'wallonisch-reformiert',
  'denomination.zj': 'Zeugen Johovas',

  'distance.100_km': '100 km',
  'distance.10_km': '10 km',
  'distance.250_km': '250 km',
  'distance.25_km': '25 km',
  'distance.500_km': '500 km',
  'distance.50_km': '50 km',

  'driving_license.a': 'Klasse-A',
  'driving_license.a1': 'Klasse-A1',
  'driving_license.b': 'Klasse-B',
  'driving_license.be': 'Klasse-BE',
  'driving_license.c': 'Klasse-C',
  'driving_license.c1': 'Klasse-C1',
  'driving_license.c1e': 'Klasse-C1E',
  'driving_license.ce': 'Klasse-CE',
  'driving_license.d': 'Klasse-D',
  'driving_license.d1': 'Klasse-D1',
  'driving_license.d1e': 'Klasse-D1E',
  'driving_license.de': 'Klasse-DE',
  'driving_license.l': 'Klasse-L',
  'driving_license.m': 'Klasse-M',
  'driving_license.old_1': '(Alte)Klasse-1',
  'driving_license.old_2': '(Alte)Klasse-2',
  'driving_license.old_3': '(Alte)Klasse-3',
  'driving_license.t': 'Klasse-T',

  'email.change_button': 'E-Mail ändern',
  'email.confirm': 'Neue E-Mail wiederholen',
  'email.new': 'Neue E-Mail',
  'email.not_match': 'E-Mail-Feld stimmt nicht überein',
  'email.success': 'Ihre E-Mail wurde erfolgreich geändert',

  'employee_search_box.button': 'Jetzt loslegen!',
  'employee_search_box.subline':
    'Stellen Sie Ihr Inserat online und finden Sie Personal!',
  'employee_search_box.title': 'Event-Mitarbeiter gesucht?',

  'employment_type.contractor': 'Selbstständig / Gewerbeschein',
  'employment_type.full_time': 'Vollzeit',
  'employment_type.part_time': 'Teilzeit',
  'employment_type.part_time_mini': 'Minijob / 538 Euro Job',
  'employment_type.student': 'Werkstudent',
  'employment_type.temporary': 'Kurzfristige Beschäftigung',
  'employment_type.training': 'Praktikum',

  'error_page.404_default_organization_text':
    'Die Seite konnte leider nicht gefunden werden. Anscheinend existiert sie nicht, ist derzeit nicht verfügbar oder der Link, den Sie gefolgt sind, ist veraltet. Überprüfen Sie auch, ob Sie die URL richtig eingegeben haben.',
  'error_page.404_default_text':
    'Die Seite konnte leider nicht gefunden werden. Anscheinend existiert sie nicht, ist derzeit nicht verfügbar oder der Link, dem du gefolgt bist, ist veraltet. Überprüfe auch, ob du die URL richtig eingegeben hast.',
  'error_page.default_410_text':
    'Beim Anzeigen dieser Seite ist ein Problem aufgetreten. Bitte versuche es später erneut.',
  'error_page.default_organization_subline':
    'Da ist wohl etwas schief gelaufen.',
  'error_page.default_subline': 'Bleib cool! Da ist nur etwas schief gelaufen.',
  'error_page.default_text':
    'Beim Anzeigen dieser Seite ist ein Problem aufgetreten. Bitte versuche es später erneut.',

  'event.audience_type_academics': 'Wissenschaftler',
  'event.audience_type_general_public': 'Allgemeine Öffentlichkeit',
  'event.audience_type_industry': 'Industrie/Branchenspez',
  'event.audience_type_mixed': 'Gemischt',
  'event.audience_type_professional': 'Fachbesucher',
  'event.audience_type_students': 'Studenten',
  'event.audience_type_trade': 'Handel',

  'event.job_alert_custom_title': 'Neue Jobs von {event_name}',
  'event.job_alert_no_jobs_title':
    'Diese Event hat zur Zeit keine offenen Jobs.',
  'event.job_alert_subline':
    'Lass dich über neue Jobs auf der {event_name} mit einem Job-Alert automatisch informieren!',
  'event.job_alert_title': 'Suche nach passenden Jobs für Event {event_title}',
  'event.job_posting_title': 'Jobangebote: {event_name}',
  'event.related_events': 'Ähnliche Events',
  'event.rotation_type_biannually': 'Halbjährlich',
  'event.rotation_type_biennially': 'Zweijährlich',
  'event.rotation_type_monthly': 'Monatlich',
  'event.rotation_type_one_time': 'Einmalig',
  'event.rotation_type_quarterly': 'Quartalsweise',
  'event.rotation_type_yearly': 'Jährlich',
  'event_details.address': 'Adresse',
  'event_details.content': '{event_category_name}-Informationen',
  'event_details.details': 'Daten auf einen Blick: {event_name}',
  'event_details.exhibition_space': 'Ausstellungsfläche',
  'event_details.number_of_exhibitors': 'Anzahl Aussteller',
  'event_details.period': 'Zeitraum',
  'event_details.rotation': 'Turnus',
  'event_details.url': 'Website',

  'eye_color.blue': 'blau',
  'eye_color.brown': 'braun',
  'eye_color.gray': 'grau',
  'eye_color.green': 'grün',
  'eye_color.powder_blue': 'graublau',

  'faq.read_less': 'WENIGER ANZEIGEN',
  'faq.read_more': 'WEITERLESEN',
  'favorites.myfavorites.title': 'Deine Favoriten',

  filter: 'Filter',
  'filter.activity': 'Aktivität',
  'filter.audience_type': 'Publikumstyp',
  'filter.category': 'Kategorie',
  'filter.date': 'Datum',
  'filter.dateOpenEnded': 'Unbefristet',
  'filter.datePeriodEnd': 'Bis',
  'filter.datePeriodStart': 'Von',
  'filter.dateStartFromNow': 'Ab sofort',
  'filter.distance': 'Umkreis',
  'filter.employment_type': 'Beschäftigungsart',
  'filter.gender': 'Geschlecht',
  'filter.highlight': 'Highlight',
  'filter.industry': 'Branche',
  'filter.last_24_hours': 'letzte 24 Stunden',
  'filter.last_3_days': 'letzte 3 Tage',
  'filter.last_4_hours': 'letzte 4 Stunden',
  'filter.last_7_days': 'letzte 7 Tage',
  'filter.last_hour': 'letzte Stunde',
  'filter.last_search_activities': 'Gewählte Aktivitäten',
  'filter.last_search_branches': 'Gewählte Branchen',
  'filter.last_search_button': 'ERNEUT SUCHEN',
  'filter.last_search_employment_types': 'Gewählte Beschäftigungsarten',
  'filter.last_search_title': 'Letzte suchen',
  'filter.missing_select_value':
    'Bitte Adresse aus der Liste wählen um die Suche auszulösen',
  'filter.more_filters': 'Weitere Filter',
  'filter.organizations_with_open_jobs': 'mit offenen Jobs',
  'filter.organizations_without_open_jobs': 'ohne offene Jobs',
  'filter.period': 'Zeitraum',
  'filter.power_job': 'Power-Job',
  'filter.release_period': 'Veröffentlichungszeitraum',
  'filter.release_period_short': 'Veröffentlicht',
  'filter.reset': 'Filter löschen',
  'filter.salary': 'Vergütung',
  'filter.search': 'Suche',
  'filter.search_term': 'Suchbegriff',
  'filter.show_less': 'Weniger anzeigen',
  'filter.show_more': 'Mehr anzeigen',
  'filter.start_search': 'Jetzt suchen',
  'filter.top_job': 'Top-Job',
  'filter.userdata.age': 'Alter',
  'filter.userdata.bodyheight': 'Größe',
  'filter.userdata.favoritesOnly': 'Favorisierte Firmen',
  'filter.userdata.gender': 'Geschlecht',
  'filter.userdata.preferredActivities': 'bevorzugte Aktivitäten',
  'filter.userdata.preferredIndustries': 'bevorzugte Branchen',
  'filter.userdata.title': 'Meine Daten',
  'filter.what': 'Was',
  'filter.what_where': 'Was/Wo',
  'filter.where': 'Wo',
  'filter.zipcode_or_city': 'Ort oder PLZ',

  'forgot.password.error':
    'Ein Fehler ist aufgetreten. Bitte versuche es erneut',
  'forgot.password.missing_email_error':
    'Bitte gib eine gültige Email Adresse an',
  'forgot.password.send_email_success':
    'Die E-Mail zum Setzen eines neuen Passworts wurde erfolgreich gesendet',
  'forgot.password.send_email_warning':
    'Du hast bereits eine E-Mail zum Zurücksetzen des Passworts angefordert. Bitte überprüfe deine E-Mails oder versuche es in Kürze erneut.',
  'forgot.password.success': 'Das Passwort wurde erfolgreich geändert',
  'forgot.password.token_not_valid': 'Das Token ist nicht gültig',

  'form.notification.success': 'Daten gespeichert',
  'form.notification.validation_error':
    'Fehler im Formular! Die roten Formularblöcke wurden nicht gespeichert!',

  'forms_hint.your_optional_resume':
    'Kein Lebenslauf zur Hand? Kein Problem - mach einfach ohne weiter.',

  'forms_label.email': 'E-Mail Adresse',
  'forms_label.frequency': 'Zustellhäufigkeit',
  'forms_label.title': 'Titel',
  'forms_label.your_email': 'Deine E-Mail',
  'forms_label.your_first_name': 'Dein Vorname',
  'forms_label.your_last_name': 'Dein Nachname',
  'forms_label.your_optional_resume': 'Dein Lebenslauf (optional)',
  'forms_label.your_password': 'Dein Passwort',

  'gender.any': 'alle',
  'gender.diverse': 'divers',
  'gender.female': 'weiblich',
  'gender.male': 'männlich',

  'general.cancel': 'abbrechen',
  'general.empty_default': 'k.A.',
  'general.submit': 'alle änderungen speichern',

  'general_question.topics_appointment_request_consultation':
    'Terminanfrage Beratung',
  'general_question.topics_general_request': 'Allgemeine Anfrage',
  'general_question.topics_help': 'Hilfe/Support',
  'general_question.topics_invoices': 'Rechnungen',
  'general_question.topics_master_data_change': 'Stammdatenänderung',
  'general_question.topics_misc': 'Sonstiges',
  'general_question.topics_new_registration': 'Neuanmeldung',
  'general_question.topics_order_booster_campaign':
    'Bestellung Booster-Kampagne',
  'general_question.topics_order_job_advertisement_package':
    'Bestellung Stellenanzeigen-Paket',
  'general_question.topics_order_job_advertisement_quota':
    'Bestellung Stellenanzeigen-Kontingent',
  'general_question.topics_order_pb_quota_search':
    'Bestellung PbSearch-Kontingent/Direktsuche',
  'general_question.topics_order_special_forms_of_advertising_features':
    'Bestellung Sonderwerbeformen/Special-Features',

  'geolocation.error_message':
    'Fehler bei der Suche nach Geolokalisierung. Bitte versuche es später noch einmal.',
  'geolocation.is_denied': 'Du musst die Standorterkennung erlauben!',
  'geolocation.unavailable':
    'Wir können Ihren Standort nicht finden. Bitte versuche später noch einmal nach.',

  'hair_color.bald': 'Glatze',
  'hair_color.black': 'schwarz',
  'hair_color.blonde': 'blond',
  'hair_color.brown': 'braun',
  'hair_color.brunette': 'brünette',
  'hair_color.dark_blonde': 'dunkelblond',
  'hair_color.dark_brown': 'dunkelbraun',
  'hair_color.gray': 'grau',
  'hair_color.light_blonde': 'hellblond',
  'hair_color.light_brown': 'hellbraun',
  'hair_color.red': 'rot',
  'hair_color.white': 'weiß',

  'hair_length.long': 'lang',
  'hair_length.middle': 'mittel',
  'hair_length.none': 'keine / Glatze',
  'hair_length.short': 'kurz',

  'health_insurance_type.private': 'privat',
  'health_insurance_type.statutory': 'gesetzlich',
  'health_insurance_type.voluntary': 'freiwillig',

  'impersonator.alert': 'Du bist nicht du selbst! Du bist',
  'impersonator.exit': 'Verlassen',

  'industry.advertising': 'Werbung / Promotion',
  'industry.airports_airlines': 'Flughäfen / -gesellschaften',
  'industry.automobile': 'Automobil',
  'industry.automobile_club': 'Automobilclubs',
  'industry.automobile_electronics': 'Car-Entertainment / -Navig.',
  'industry.building_material_trading': 'Baumarkt / Baustoff / DIY',
  'industry.car_navigation_systems': 'Car-Navigation',
  'industry.care_products': 'Pflegemittel',
  'industry.clothing': 'Textilien',
  'industry.computer': 'Computer',
  'industry.consumer_electronics': 'Unterhaltungselektronik',
  'industry.culture': 'Kultur / Kunst',
  'industry.culture_clubs': 'Clubs / Diskotheken',
  'industry.culture_museum': 'Galerien / Museen / Theater',
  'industry.culture_music': 'Musik',
  'industry.education': 'Bildungsbereich',
  'industry.electric_power_tools': 'Elektrowerkzeuge',
  'industry.electronics': 'Elektronik',
  'industry.electronics_hardware_tools': 'E-Geräte / -Werkzeuge',
  'industry.energy': 'Energie- / Mineralölbranche',
  'industry.engineering': 'Maschinenbau',
  'industry.entertainment': 'Vergnügungsparks',
  'industry.festivals': 'Festivals / Konzerte',
  'industry.finance': 'Leasing / Finanzen',
  'industry.finance_bank': 'Banken / Sparkassen',
  'industry.finance_credit_cards': 'Kreditkarten',
  'industry.finance_discount_cards': 'Rabattkarten',
  'industry.finance_insurance': 'Versicherungen',
  'industry.food': 'Lebens- / Genußmittel',
  'industry.food_drinks': 'Getränke',
  'industry.food_foodstuff': 'Nahrungsmittel',
  'industry.food_gastronomy': 'Gastronomie',
  'industry.food_tobaccos': 'Zigaretten / Tabakwaren',
  'industry.foto': 'Fernsehen / Film / Foto',
  'industry.fundraising': 'Fundraising',
  'industry.furniture': 'Wohnbedarf / Möbel / Einrichtungen',
  'industry.hardware': 'Haushaltswaren',
  'industry.lifestyle': 'Lifestyle',
  'industry.lifestyle_accessoires': 'Schmuck / Uhren',
  'industry.lifestyle_cosmetics': 'Kosmetik / Parfüms',
  'industry.lifestyle_mode': 'Mode / Textilie',
  'industry.logistics': 'Logistik',
  'industry.market_research': 'Marktforschung',
  'industry.media': 'Medien',
  'industry.media_internet': 'Internet',
  'industry.media_it': 'IT-Dienstleistungen',
  'industry.media_tv_print': 'TV / Radio / Print',
  'industry.medicine': 'Gesundheit / Medizin',
  'industry.misc': 'Sonstiges',
  'industry.npo': 'Non-Profit Organisationen',
  'industry.office_supplies': 'Bürobedarf / Schreibwaren',
  'industry.paddle': 'Spielekonsolen',
  'industry.pet_accessories': 'Tierbedarf',
  'industry.photo': 'Film und Foto',
  'industry.public_transport': 'ÖPNV (öff. Pers.nahverk.)',
  'industry.real_estate': 'Immobilien',
  'industry.retail_trade': 'Einzel- / Fachhandel',
  'industry.sport_goods': 'Sport / Fitness / Wellness',
  'industry.telecom': 'Telekommunikation',
  'industry.tourism': 'Tourismus',
  'industry.toys': 'Spielwaren',
  'industry.tradefair': 'Messen / Events',
  'industry.various': 'Diverse',
  'industry.watches': 'Uhren',
  'industry.wellness': 'Wellness',

  'interval.daily': 'täglich',
  'interval.four_hourly': 'alle 4 Stunden',
  'interval.hourly': 'stündlich',
  'interval.weekly': 'wöchentlich',

  'invoices.date': 'Rechnungsdatum:',
  'invoices.followed': 'Anruf, nachgehakt',
  'invoices.last_edited': 'Zuletzt bearbeitet:',
  'invoices.pay': 'Rechnung beglichen',
  'invoices.recipient': 'Rechnungsempfänger:',
  'invoices.sent': 'Rechnung versendet',

  'job.back_to_overview': 'Zurück zu den Jobs',

  'job.box_type_default': 'Jobs',
  'job.box_type_newjobs': 'Neue Jobs',
  'job.box_type_opportunities': 'Job Chancen',
  'job.box_type_recommendations': 'Job Empfehlungen',
  'job.box_type_topoffers': 'Top Jobs',

  'job.discover_more': 'Mehr erfahren',
  'job.see_jobs': 'Jobangebote ansehen',

  'job_alert.button': 'Job-Alert erstellen',
  'job_alert.default_box_subline':
    'Lass dich über Jobs automatisch informieren!',
  'job_alert.default_empty_box_title':
    'Lass dich über neue {job_box_type} zu deiner Suche mit Job-Alerts automatisch informieren!',
  'job_alert.default_empty_box_title_no_jobs':
    'Momentan gibt es keine Jobs, die deinen Suchkriterien entsprechen.',
  'job_alert.dialog_button': 'Job-Alert speichern',
  'job_alert.dialog_intro_text':
    'Erhalte Benachrichtigungen anhand deiner Suchparameter per E-Mail. Du kannst den Titel des Alerts ändern sowie den Intervall der Zustellung anpassen. Eine Übersicht deiner Alerts findest du in deiner Sedcard unter „Mein Account“ - „Job-Alerts“, wo du sie verwalten und nachträglich bearbeiten/anpassen kannst.',
  'job_alert.dialog_notification_empty_filter': 'Dein Filter ist leer!',
  'job_alert.dialog_notification_invalid_mautic_id':
    'Ihre Mautic ID ist ungültig. Bitte versuche es später erneut.',
  'job_alert.dialog_notification_success':
    'Der Job-Alert wurde erfolgreich gespeichert!',
  'job_alert.dialog_title': ' Job-Alert erstellen ',
  'job_alert.element_button': 'Änderungen speichern',
  'job_alert.element_notification_confirm':
    'Der Job-Alert wurde erfolgreich bestätigt!',
  'job_alert.element_notification_confirm_error':
    'Fehler beim Bestätigen des Job-Alerts. Bitte versuche es später erneut.',
  'job_alert.element_notification_delete':
    'Der Job-Alert wurde erfolgreich entfernt!',
  'job_alert.element_notification_delete_error':
    'Fehler beim Entfernen des Job-Alerts. Bitte versuche es später erneut.',
  'job_alert.element_notification_paused': 'Der Job-Alert wurde pausiert!',
  'job_alert.element_notification_paused_error':
    'Fehler beim Pausieren des Job-Alerts. Bitte versuche es später erneut.',
  'job_alert.element_notification_unpaused': 'Der JobAlert wurde aktiviert!',
  'job_alert.element_notification_unpaused_error':
    'Fehler beim Aktivieren des Job-Alerts. Bitte versuche es später erneut.',
  'job_alert.element_notification_updated': 'Der Job-Alert wurde aktualisiert!',
  'job_alert.element_notification_updated_error':
    'Fehler beim Ändern des Job-Alerts. Bitte versuche es später erneut.',
  'job_alert.job_details_box':
    'Werde automatisch über ähnliche Jobs informiert!',

  'job_application.benefits': 'Was wir dir bieten!',
  'job_application.description': 'Was dich erwartet!',
  'job_application.requirement': 'Was wir erwarten!',

  'job_box-no-jobs_found_button': 'zur myData-Seite',
  'job_box.complete_data': 'Daten erweitern',
  'job_box.favourites': 'Job-Empfehlungen',
  'job_box.new_jobs': 'Neue Jobs',
  'job_box.no_job_recommendations_found':
    'Leider haben wir keine passenden Jobs für deine Arbeitsvorlieben in deinem Umkreis gefunden. \n Schau zu einem späteren Zeitpunkt wieder rein. Täglich kommen viele neue Jobangebote dazu!',
  'job_box.no_jobs_found':
    'Leider haben wir keine passenden Jobs gefunden. \n Schau zu einem späteren Zeitpunkt wieder rein. Täglich kommen viele neue Jobangebote dazu!',
  'job_box.not_complete_data':
    'Du hast nicht alle erforderlichen Angaben in deinem Profil hinterlegt. \n Klicke auf den Button um deine Daten zu erweitern.',
  'job_box.opportunities': 'Job-Chancen',
  'job_box.recommendations': 'Job-Empfehlungen',
  'job_box.similar_offers': 'Ähnliche Jobangebote',
  'job_box.top_offers': 'Top-Jobs',

  'job_card.application_date': 'Beworben am: {application_date}',
  'job_card.application_url_button': 'Externe Bewerbung öffnen',
  'job_card.application_url_message':
    'Die Bewerbung auf diesen Job soll über ein externen System abgegeben wird. Dieses wurde in einem neuen Fenster geöffnet. Sollte diese Weiterleitung nicht funktionieren kannst du den Link nutzen.',
  'job_card.apply_now': 'Jetzt bewerben!',
  'job_card.more_details': 'Mehr Details',
  'job_card.more_details_short': 'Details',
  'job_card.ratings': 'Bewertungen',
  'job_card.register_and_apply': 'Jetzt bewerben',
  'job_card.salary_on_request': 'auf Anfrage',
  'job_card.salary_per': 'Vergütung {salary_type}',
  'job_card.total_ratings': '{num_ratings} Bewertungen',

  'job_details.action_locations': 'Aktionsstandorte',
  'job_details.activities': 'Tätigkeit',
  'job_details.already_applied':
    'Du hast Dich bereits am {application_date} auf diesen Job beworben.',
  'job_details.at_pb_since': 'Bei Promotionbasis seit',
  'job_details.at_pb_since_short': 'Bei PB seit',
  'job_details.benefits': 'Vorteile',
  'job_details.brands': 'Marken / Produkte',
  'job_details.company_contact': 'Ansprechpartner & Kontaktdaten',
  'job_details.company_details': 'Jobanbieter',
  'job_details.content': 'Aktionsinhalt',
  'job_details.details': 'Jobdetails',
  'job_details.employment_types': 'Form der Zusammenarbeit',
  'job_details.industries': 'Branche',
  'job_details.job_id': 'Job-Nr.',
  'job_details.location_description': 'Anmerkungen zu Aktionsstandorten',
  'job_details.more_infos_back': 'findest du hier!',
  'job_details.more_infos_front': 'Mehr Infos zu',
  'job_details.nation_wide_locations':
    'Viele bundesweite Aktionsstandorte - auch in deiner Nähe!',
  'job_details.no_locations': 'keine Aktionsstandorte hinterlegt',
  'job_details.no_locations_short': 'keine Aktionsstandorte',
  'job_details.not_available_notification':
    'Du möchtest schnell und einfach über neue Jobs informiert werden?',
  'job_details.not_available_subtitle':
    'Du möchtest über neue Jobs schnell und einfach informiert werden?',
  'job_details.not_available_title': 'Dieses inserat ist nicht mehr verfügbar.',
  'job_details.period': 'Zeitraum',
  'job_details.period_description': 'Anmerkungen zu Zeiten / Einsatz',
  'job_details.register': 'Jetzt registrieren!',
  'job_details.requirement': 'Anforderungsprofil',
  'job_details.route_my_applications': 'Zu meinen Bewerbungen',
  'job_details.salary_base': 'Vergütung',
  'job_details.salary_description': 'Anmerkungen zu Vergütungen',
  'job_details.salary_overnight_stay': 'Übernachtungspauschale',
  'job_details.salary_travel_expenses': 'KM-Pauschale / Fahrtkosten',
  'job_details.share': 'JOBANGEBOT TEILEN',
  'job_details.share_dialog_subline':
    'Gemeinsam arbeiten macht alles besser! Lade Freunde ein, und taucht zusammen in die Welt von Promotion, Messen und Events ein. Ein Klick, und ihr könnt gemeinsam durchstarten!',
  'job_details.share_dialog_title':
    'Mach mehr aus deiner Bewerbung – teile den Spaß!',
  'job_details.similar_offers': 'Ähnliche Jobangebote',
  'job_details.special_payments': 'Sonderzahlungen',
  'job_details.tags': 'Stichworte / Tags',
  'job_details.working_time': 'Arbeitszeit',

  'job_employment_status.between_school_and_study':
    'Zwischen Schule und Studium',
  'job_employment_status.between_school_and_training':
    'Zwischen Schule und Ausbildung',
  'job_employment_status.between_school_and_work': 'Zwischen Schule und Arbeit',
  'job_employment_status.employee_full_time': 'Vollzeit Arbeitnehmer',
  'job_employment_status.employee_part_time': 'Teilzeit Arbeitnehmer',
  'job_employment_status.freelancer':
    'Hauptberuflich selbstständige Erwerbstätigkeit',
  'job_employment_status.homemaker': 'Hausfrau / Hausmann',
  'job_employment_status.minijobber': 'Minijobber (geringfügig beschäftigt)',
  'job_employment_status.misc': 'Sonstiger',
  'job_employment_status.pensioner': 'Altersrentner',
  'job_employment_status.scholar': 'Schüler (Vollzeit)',
  'job_employment_status.student': 'Student (Vollzeit)',
  'job_employment_status.trainee': 'Auszubildender',
  'job_employment_status.unemployed': 'Arbeitslos / arbeitssuchend gemeldet',
  'job_employment_status.voluntary_year': 'Freiwilliges Jahr (FSJ / BFD / FÖJ)',

  'language.aa': 'Afar',
  'language.ab': 'Abchasisch',
  'language.af': 'Afrikaans',
  'language.am': 'Amharisch',
  'language.ar': 'Arabisch',
  'language.as': 'Assamesisch',
  'language.ay': 'Aymara',
  'language.az': 'Aserbaidschanisch',
  'language.ba': 'Baschkirisch',
  'language.be': 'Belorussisch',
  'language.bg': 'Bulgarisch',
  'language.bh': 'Biharisch',
  'language.bi': 'Bislamisch',
  'language.bn': 'Bengalisch',
  'language.bo': 'Tibetanisch',
  'language.br': 'Bretonisch',
  'language.bs': 'Bosnisch',
  'language.ca': 'Katalanisch',
  'language.cel-gaulish': 'Gallisch',
  'language.co': 'Korsisch',
  'language.cs': 'Tschechisch',
  'language.cy': 'Walisisch',
  'language.da': 'Dänisch',
  'language.de': 'Deutsch',
  'language.de-at': 'österreichisches Deutsch',
  'language.de-ch': 'Schweizerdeutsch',
  'language.dz': 'Dzongkha,Bhutani',
  'language.el': 'Griechisch',
  'language.en': 'Englisch',
  'language.en-au': 'australisches Englisch',
  'language.en-ca': 'kanadisches Englisch',
  'language.en-gb': 'britisches Englisch',
  'language.en-uk': 'königlich-britisches Englisch',
  'language.en-us': 'amerikanisches Englisch',
  'language.eo': 'Esperanto',
  'language.es': 'Spanisch',
  'language.et': 'Estnisch',
  'language.eu': 'Baskisch',
  'language.fa': 'Persisch',
  'language.fi': 'Finnisch',
  'language.fj': 'NaVosaVakaviti',
  'language.fo': 'Färöisch',
  'language.fr': 'Französisch',
  'language.fr-ca': 'kanadisches Französisch',
  'language.fy': 'Friesisch',
  'language.ga': 'Irisch',
  'language.gd': 'Schottisches Gälisch',
  'language.gl': 'Galizisch',
  'language.gn': 'Guarani',
  'language.gu': 'Gujaratisch',
  'language.ha': 'Haussa',
  'language.he': 'Hebräisch',
  'language.hi': 'Hindi',
  'language.hr': 'Kroatisch',
  'language.hu': 'Ungarisch',
  'language.hy': 'Armenisch',
  'language.ia': 'Interlingua',
  'language.id': 'Indonesisch',
  'language.ie': 'Interlingue',
  'language.ik': 'Inupiak',
  'language.is': 'Isländisch',
  'language.it': 'Italienisch',
  'language.iu': 'Inuktitut (Eskimo)',
  'language.ja': 'Japanisch',
  'language.ji': 'Jiddish',
  'language.jv': 'Javanisch',
  'language.ka': 'Georgisch',
  'language.kk': 'Kasachisch',
  'language.kl': 'Kalaallisut (Grönländisch)',
  'language.km': 'Kambodschanisch',
  'language.kn': 'Kannada',
  'language.ko': 'Koreanisch',
  'language.ks': 'Kaschmirisch',
  'language.ku': 'Kurdisch',
  'language.ky': 'Kirgisisch',
  'language.la': 'Lateinisch',
  'language.ln': 'Lingala',
  'language.lo': 'Laotisch',
  'language.lt': 'Litauisch',
  'language.lv': 'Lettisch',
  'language.mg': 'Malagasisch',
  'language.mi': 'Maorisch',
  'language.mk': 'Mazedonisch',
  'language.ml': 'Malajalam',
  'language.mn': 'Mongolisch',
  'language.mo': 'Moldavisch',
  'language.mr': 'Marathi',
  'language.ms': 'Malaysisch',
  'language.mt': 'Maltesisch',
  'language.my': 'Burmesisch',
  'language.na': 'Nauruisch',
  'language.ne': 'Nepalesisch',
  'language.nl': 'Niederländisch',
  'language.no': 'Norwegisch',
  'language.oc': 'Okzitanisch',
  'language.om': 'Oromo',
  'language.or': 'Oriya',
  'language.pa': 'Pundjabisch',
  'language.pl': 'Polnisch',
  'language.ps': 'Paschtu',
  'language.pt': 'Portugiesisch',
  'language.qu': 'Quechua',
  'language.rm': 'Rätoromanisch',
  'language.rn': 'Kirundisch',
  'language.ro': 'Rumänisch',
  'language.ru': 'Russisch',
  'language.rw': 'Kijarwanda',
  'language.sa': 'Sanskrit',
  'language.sd': 'Zinti',
  'language.sg': 'Sango',
  'language.sh': 'Serbokroatisch',
  'language.si': 'Singhalesisch',
  'language.sk': 'Slowakisch',
  'language.sl': 'Slowenisch',
  'language.sm': 'Samoanisch',
  'language.sn': 'Schonisch',
  'language.so': 'Somalisch',
  'language.sq': 'Albanisch',
  'language.sr': 'Serbisch',
  'language.ss': 'Swasiländisch',
  'language.st': 'Sesothisch',
  'language.su': 'Sudanesisch',
  'language.sv': 'Schwedisch',
  'language.sw': 'Suaheli',
  'language.ta': 'Tamilisch',
  'language.te': 'Tegulu',
  'language.tg': 'Tadschikisch',
  'language.th': 'Thai',
  'language.ti': 'Tigrinja',
  'language.tk': 'Turkmenisch',
  'language.tl': 'Tagalog',
  'language.tn': 'Sezuan',
  'language.to': 'Tongaisch',
  'language.tr': 'Türkisch',
  'language.ts': 'Tsongaisch',
  'language.tt': 'Tatarisch',
  'language.tw': 'Twi',
  'language.ug': 'Uigur',
  'language.uk': 'Ukrainisch',
  'language.ur': 'Urdu',
  'language.uz': 'Usbekisch',
  'language.vi': 'Vietnamesisch',
  'language.vo': 'Volapük',
  'language.wo': 'Wolof',
  'language.xh': 'Xhosa',
  'language.yi': 'Jiddish',
  'language.yo': 'Joruba',
  'language.za': 'Zhuang',
  'language.zh': 'Chinesisch',
  'language.zh-guoyu': 'Mandarin-Chinesisch',
  'language.zh-min-nan': 'Min-Nan-Chinesisch',
  'language.zh-tw': 'Taiwan-Chinesisch',
  'language.zh-xiang': 'Xiang-Chinesisch',
  'language.zu': 'Zulu',

  'language_level.advanced': 'Fortgeschritten',
  'language_level.basic': 'Grundkenntnisse',
  'language_level.expert': 'Fließend',
  'language_level.native': 'Muttersprache',

  'mautic_form.error_message': 'Fehler beim Senden des Formulars',
  'mautic_form.success_message': 'Formular erfolgreich gesendet',
  'meta.loading_page': 'Loading Page Image',
  'meta.logo_img_alt':
    'Finde deinen nächsten Promotionjob, Messejob oder Eventjob bei Promotionbasis',
  'meta.logo_link_title':
    'Finde deinen nächsten Promotionjob, Messejob oder Eventjob bei Promotionbasis',

  'no_search_results.button_text': 'Filter zurücksetzen',
  'no_search_results.content':
    'Vielleicht wählst du einen anderen Filter aus, um andere Infos zu finden. Oder du setzt den Filter zurück, um dir alle Daten anschauen zu können.',
  'no_search_results.content_organization_context':
    'Die Suche ergab leider keine Treffer! Wählen Sie einen anderen Filter aus, um andere Informationen zu finden oder setzen Sie den Filter zurück, um alle Artikel und Ressourcen anschauen zu können.',
  'no_search_results.jobs.content':
    'Vielleicht wählst du einen anderen Filter aus, um coole Jobs zu finden. Oder du setzt den Filter zurück, um dir alle Stellenangebote anschauen zu können.',
  'no_search_results.subtitle': 'Die Suche ergab leider keine Treffer! ',
  'no_search_results.title': 'Huch...',

  'oauth.error': 'Es ist ein Fehler aufgetreten',
  'oauth.error.100': 'Für diese E-Mail Adresse existiert kein Account',
  'oauth.error.101':
    'Für diese E-Mail Adresse existiert ein JobAnbieter Account',
  'offline.alert':
    'Keine Internetverbindung verfügbar. Die Seite steht als Ansicht ohne klickbare Funktionen weiter zur Verfügung.',

  orderBy: 'Sortierung',
  'orderBy.agency.asc': 'Agentur/Firma aufsteigend',
  'orderBy.agency.desc': 'Agentur/Firma absteigend',
  'orderBy.application_date.asc': 'Bewerbungsdatum aufsteigend',
  'orderBy.application_date.desc': 'Bewerbungsdatum absteigend',
  'orderBy.billing_creation_date.asc': 'Erstellungsdatum aufsteigend',
  'orderBy.billing_creation_date.desc': 'Erstellungsdatum absteigend',
  'orderBy.billing_editing_date.asc': 'Änderungsdatum aufsteigend',
  'orderBy.billing_editing_date.desc': 'Änderungsdatum absteigend',
  'orderBy.billing_nr.asc': 'Rechnungsnummer aufsteigend',
  'orderBy.billing_nr.desc': 'Rechnungsnummer absteigend',
  'orderBy.billing_title.asc': 'Rechnungstitel aufsteigend',
  'orderBy.billing_title.desc': 'Rechnungstitel absteigend',
  'orderBy.date.asc': 'Datum aufsteigend',
  'orderBy.date.desc': 'Datum absteigend',
  'orderBy.job_title.asc': 'Job-Titel aufsteigend',
  'orderBy.job_title.desc': 'Job-Titel absteigend',
  'orderBy.open_jobs.asc': 'Anzahl Jobs aufsteigend',
  'orderBy.open_jobs.desc': 'Anzahl Jobs absteigend',
  'orderBy.order_number.asc': 'Standardsortierung aufsteigend',
  'orderBy.order_number.desc': 'Standardsortierung absteigend',
  'orderBy.rank.asc': 'Relevanz aufsteigend',
  'orderBy.rank.desc': 'Relevanz absteigend',
  'orderBy.rating.asc': 'Bewertungen aufsteigend',
  'orderBy.rating.desc': 'Bewertungen absteigend',
  'orderBy.title.asc': 'Titel aufsteigend',
  'orderBy.title.desc': 'Titel absteigend',

  'organization.job_alert_custom_title': 'Neue Jobs von {organization_name}',
  'organization.job_alert_no_jobs_title':
    'Dieser Jobanbieter hat zur Zeit keine offenen Jobs.',
  'organization.job_alert_subline':
    'Lass dich über neue Jobs von {organization_name} mit einem Job-Alert automatisch informieren!',
  'organization.learn_less': 'Zeige weniger',
  'organization.learn_more': 'Mehr erfahren',
  'organization.logo_alt': '{organization_name} Logo',
  'organization.logo_title': '{organization_name} Logo',
  'organization.profile_link': 'Zum Firmenprofil von {organization_name}',
  'organization.question_activities': 'Tätigkeit / Anforderung*',
  'organization.question_company': 'Firma',
  'organization.question_email': 'E-Mail',
  'organization.question_error':
    'Fehler beim Senden der Nachricht an die Jobanbieter!',
  'organization.question_error_listing':
    'Fehler beim Senden der Projekt-Anfrage!',
  'organization.question_general_error': 'Fehler beim Senden der Anfrage!',
  'organization.question_general_success':
    'Die Anfrage wurde erfolgreich gesendet. Wir werden diese bearbeiten und ihnen Feedback geben.',
  'organization.question_message': 'Nachricht',
  'organization.question_name': 'Name',
  'organization.question_personnel_requirement': 'Personalbedarf',
  'organization.question_phone': 'Telefon',
  'organization.question_project_description': 'Projektbeschreibung',
  'organization.question_project_general_message': 'Nachricht',
  'organization.question_success': 'Die Nachricht wurde erfolgreich gesendet!',
  'organization.question_success_in_listing':
    'Die Projekt-Anfrage wurde erfolgreich gesendet. Wir werden diese bearbeiten und ihnen Feedback geben.',
  'organization.question_topic': 'Thema',
  'organization.question_topics_current_job_offers':
    'Aktuelle Jobangebote / Allgemeines',
  'organization.question_topics_misc': 'Sonstiges',
  'organization.question_topics_my_applications':
    'Meine Bewerbung / Status & Nachtrag',
  'organization.question_topics_my_jobs': 'Meine Jobs / Abrechung & Co.',
  'organization.question_topics_project_request_conception':
    'Projektanfrage / Konzeption',
  'organization.question_topics_project_request_full_service':
    'Projektanfrage / Fullservice',
  'organization.question_topics_project_request_staff':
    'Projektanfrage / Personal',
  'organization.registration_city_label': 'Ort',
  'organization.registration_company_name_label': 'Firmenname',
  'organization.registration_country_label': 'Land',
  'organization.registration_email_label': 'E-Mail/Login',
  'organization.registration_error_in_form':
    'Bitte überprüfe deine Daten und versuchen Sie es erneut',
  'organization.registration_first_name_label': 'Vorname',
  'organization.registration_homepage_label': 'Homepage',
  'organization.registration_house_number_label': 'Hausnr.',
  'organization.registration_last_name_label': 'Nachname',
  'organization.registration_phone_label': 'Telefon',
  'organization.registration_street_label': 'Straße',
  'organization.registration_success_in_form':
    'Sie haben sich erfolgreich registriert',
  'organization.registration_ust_id_nr_label': 'USt-IdNr',
  'organization.registration_zipcode_label': 'PLZ',
  'organization_activities_and_industries.title': 'Hauptbranchen & Leistungen',

  'organization_details.favorites': 'Favorisierungen',
  'organization_details.founding_year': 'gegründet',
  'organization_details.number_of_employees': 'Mitarbeiter',
  'organization_details.number_of_working_days_per_year':
    'Aktionstage pro Jahr',
  'organization_details.ratings': 'Bewertungen',

  'page.articles': 'Tipps & FAQ',
  'page.events': 'Messen-Events',
  'page.faqs': 'FAQs',
  'page.index': 'Startseite',
  'page.job_not_found': 'Job nicht gefunden',
  'page.jobs': 'Jobangebote',
  'page.landingpage_activities': 'Jobangebote die zu dir passen',
  'page.landingpage_cities': 'Jobangebote in deiner Stadt',
  'page.landingpage_eventjobs': 'Eventjobs in deiner Stadt',
  'page.landingpage_ferienjobs': 'Ferienjobs in deiner Stadt',
  'page.landingpage_gastrojobs': 'Gastrojobs in deiner Stadt',
  'page.landingpage_messejobs': 'Messejobs in deiner Stadt',
  'page.landingpage_nebenjobs': 'Nebenjobs in deiner Stadt',
  'page.landingpage_promotionjobs': 'Promotionjobs in deiner Stadt',
  'page.landingpage_rentnerjobs': 'Rentnerjobs in deiner Stadt',
  'page.landingpage_schuelerjobs': 'Schülerjobs in deiner Stadt',
  'page.landingpage_studentenjobs': 'Studentenjobs in deiner Stadt',
  'page.myApplications': 'Meine Bewerbungen',
  'page.myByllAccount': 'Mein Byll Account / Rechnungen',
  'page.myDashboard': 'Mein Dashboard',
  'page.myData': 'Meine Daten',
  'page.myDocuments': 'Meine Dokumenten',
  'page.myEmailChange': 'Meine E-Mail ändern',
  'page.myFavorites': 'Meine Favoriten',
  'page.myImages': 'Meine Bilder',
  'page.myInvoices': 'Meine Rechnungen',
  'page.myJobAlerts': 'Meine Job-Alerts',
  'page.myPasswordChange': 'Meine Passwort ändern',
  'page.myRatings': 'Meine Bewertungen',
  'page.mySettings': 'Meine Einstellungen',
  'page.myVideos': 'Meine Videos',
  'page.not_allowed': 'Du  musst dich anmelden, um diese Seite zu sehen!',
  'page.organizations': 'Jobanbieter',
  'page.wikis': 'Wiki',

  'password.change_button': 'Passwort ändern',
  'password.confirm': 'Neues Passwort wiederholen',
  'password.current': 'Aktuelles Passwort',
  'password.match_old': 'Passwort muss anders als das alte sein',
  'password.new': 'Neues Passwort',
  'password.no_match': 'Passwörter müssen übereinstimmen',
  'password.old_wrong': 'Altes Passwort falsch',
  'password.required': 'Passwort wird benötigt',
  'password.required_lowercase':
    'Passwort muss mindestens ein kleines Zeichen beinhalten!',
  'password.required_number': 'Passwort enthält nicht mindestens eine Ziffer!',

  'password.required_size': 'Passwort ist nicht mindestens 8 Zeichen lang!',
  'password.required_special_character':
    'Das Passwort enthält nicht mindestens eines der folgenden Sonderzeichen: ()[]{}?!$%&/=*+~,.;:<>-_@#',
  'password.required_uppercase':
    'Passwort enthält nicht mindestens einen Großbuchstaben!',
  'password.wrong': 'Falsches Passwort',

  'pricing_tables.advanced_multiposting1':
    'Erweitertes Multiposting mit mehr als',
  'pricing_tables.advanced_multiposting2': '100 Plattformen',
  'pricing_tables.applicant_export': 'Bewerber-Export',
  'pricing_tables.contingents_switch': 'Preise mit Abo anzeigen',
  'pricing_tables.discount': 'Pauschalrabatt auf fast alle Leistungen',
  'pricing_tables.flexible_durations1': 'Flexible Laufzeiten: 2 Wochen,',
  'pricing_tables.flexible_durations2': '1, 2 oder 3 Monate',
  'pricing_tables.free_jobs_per_month': 'Kostenfreie Inserate pro Monat',
  'pricing_tables.jobposting_button': '{job_name} schalten',
  'pricing_tables.num_locations': 'Inklusiv-Standorte',
  'pricing_tables.price': 'Preis',
  'pricing_tables.price_jobpostings': 'Preis pro Stellenanzeige',
  'pricing_tables.price_per_application_jobpostings': 'Preis pro Bewerbung',
  'pricing_tables.price_per_contact': 'Preis pro Kontakt',
  'pricing_tables.price_per_listing': 'Preis pro Inserat',
  'pricing_tables.price_per_quota': 'Preis pro Kontigent',
  'pricing_tables.simple_multiposting1': 'Einfaches Multiposting mit mehr',
  'pricing_tables.simple_multiposting2': 'als 50 Plattformen',
  'pricing_tables.url_routing': 'URL-Routing',
  'pricing_tables.visual_highlighting': 'Optische Hervorhebung im Listing',

  'profile.activity-skills.radio_1': 'JA',
  'profile.activity-skills.radio_2': 'NEIN',
  'profile.activity-skills.radio_label':
    'Hast du Erfahrungen im Bereich Promotion/Messe/Event?',
  'profile.activity-skills.switch':
    'Ja, ich habe Erfahrungen im Bereich Promotion/Messe/Event!',

  'profile.address.city': 'Stadt',
  'profile.address.country': 'Land',
  'profile.address.nextCity': 'nächste Stadt',
  'profile.address.street': 'Straße',
  'profile.address.zipcode': 'PLZ',

  'profile.appearance.bodyHeight': 'Körpergröße in cm',
  'profile.appearance.eyeColor': 'Augenfarbe',
  'profile.appearance.hairColor': 'Haarfarbe',
  'profile.appearance.hairLength': 'Haarlänge',
  'profile.appearance.hasContactLenses': 'Kontaktlinsen',
  'profile.appearance.hasGlasses': 'Brille',
  'profile.appearance.hasPiercing': 'Piercing',
  'profile.appearance.hasTattoo': 'Tattoo',
  'profile.appearance.piercingLocation': 'Piercing-Stelle',
  'profile.appearance.sizeOfClothing': 'Konfektion',
  'profile.appearance.sizeOfPants': 'Jeansgröße',
  'profile.appearance.sizeOfShirt': 'Kleidungsgröße',
  'profile.appearance.sizeOfShoe': 'Schuhgröße',
  'profile.appearance.tattooLocation': 'Tattoo-Stelle',

  'profile.bank-data.accountHolder': 'Kontoinhaber',
  'profile.bank-data.bankName': 'Bank',
  'profile.bank-data.bic': 'BIC',
  'profile.bank-data.iban': 'IBAN',

  'profile.communication_data_type.email': 'E-Mail',
  'profile.communication_data_type.fax': 'Fon(2)',
  'profile.communication_data_type.mobile': 'Mobil',
  'profile.communication_data_type.tel': 'Fon(1)',
  'profile.communication_data_type.url': 'Homepage',

  'profile.created': 'Bei PB seit {date} um {time} Uhr',

  'profile.health-insurance.healthInsuranceName': 'Krankenversicherung',
  'profile.health-insurance.healthInsuranceNumber':
    'Krankenversicherungsnummer',
  'profile.health-insurance.healthInsuranceType': 'Art der Krankenversicherung',

  'profile.job_employment.status': 'Aktueller beruflicher Status',
  'profile.job_employment.status_date_from': 'Von',
  'profile.job_employment.status_date_until': 'Bis',
  'profile.job_employment.status_message':
    'Zum Nachweis des Status müssen entsprechende Dokumente im Bereich „Meine Dokumente“ hochgeladen werden.',
  'profile.job_employment.status_open_ended': 'unbefristet',

  'profile.last_data_update': 'Letztes Daten-Update am {date} um {time} Uhr',
  'profile.last_image_update': 'Letztes Bilder-Update am {date} um {time} Uhr',
  'profile.last_login': 'Letzter Login am {date} um {time} Uhr',
  'profile.last_login_relative_date': 'Letzter Login {date}',

  'profile.mobility.carType': 'Auto / Typ / Marke',
  'profile.mobility.drivingLicenses': 'Führerscheinklassen',
  'profile.mobility.ownCar': 'eigenes Auto',

  'profile.personal-data.birthDate': 'Geburtstag',
  'profile.personal-data.firstName': 'Vorname',
  'profile.personal-data.gender': 'Geschlecht',
  'profile.personal-data.lastName': 'Nachname',
  'profile.personal-data.nationality': 'Staatsangehörigkeit',
  'profile.personal-data.profileSummary': 'Über mich...',
  'profile.personal-data.skinType': 'Hauttyp',

  'profile.personnel-accounting.numberOfDaysRule':
    'Die Anzahl der Tage darf nicht größer als 365 sein',
  'profile.personnel-accounting.shortTermEmploymentWorkingDaysCurrentYear':
    'Anzahl Tage kurzf. Beschäftigung aktuelles Kalenderjahr',
  'profile.personnel-accounting.shortTermEmploymentWorkingDaysLastYear':
    'Anzahl Tage kurzf. Beschäftigung letztes Kalenderjahr',

  'profile.promotion-experience.activity': 'Tätigkeit',
  'profile.promotion-experience.description': 'Beschreibung',
  'profile.promotion-experience.industry': 'Branche',
  'profile.promotion-experience.organization': 'Arbeitgeber / Unternehmen',
  'profile.promotion-experience.periodFrom': 'Beginn',
  'profile.promotion-experience.periodUntil': 'Ende',
  'profile.promotion-experience.title': 'Titel / Bezeichnung',

  'profile.schooling.date': 'Abschlussdatum',
  'profile.schooling.degree': 'Abschluss',
  'profile.schooling.description': 'Beschreibung',
  'profile.schooling.organization': 'Schule / Einrichtung',
  'profile.schooling.title': 'Titel / Bezeichnung',

  'profile.sedcard_last_login': 'LETZER LOGIN: ',
  'profile.sedcard_link': 'SO SEHEN MICH JOBANBIETER / SEDCARD-ANSICHT',

  'profile.skills.level': 'Kenntnisgrad',
  'profile.skills.skill': 'Skill / Fähigkeit',

  'profile.social-profile.type': 'Typ',
  'profile.social-profile.value': 'Link / URL',

  'profile.tax.denomination': 'Konfession',
  'profile.tax.salesTaxDeductible': 'USt. abzugsfähig',
  'profile.tax.salesTaxIdentificationNumber': 'USt-IdNr.',
  'profile.tax.taxIdentificationNumber': 'Steuer-Identnummer',
  'profile.tax.taxNumber': 'Steuernummer',
  'profile.tax.taxOffice': 'Finanzamt / Ort',
  'profile.tax.taxSmallBusiness': 'Kleinunternehmer nach §19 UStG',

  'profile.work-experience.activity': 'Tätigkeit',
  'profile.work-experience.description': 'Beschreibung',
  'profile.work-experience.industry': 'Branche',
  'profile.work-experience.organization': 'Arbeitgeber / Unternehmen',
  'profile.work-experience.periodFrom': 'Beginn',
  'profile.work-experience.periodUntil': 'Ende',
  'profile.work-experience.title': 'Titel / Bezeichnung',

  'profile.work-preference.preferenceSummary': 'Zusammenfassung / Beschreibung',
  'profile.work-preference.preferredActivities': 'bevorzugte Aktivitäten',
  'profile.work-preference.preferredEmploymentTypes':
    'bevorzugte Formen der Zusammenarbeit / Beschäftigung',
  'profile.work-preference.preferredIndustries': 'bevorzugte Branchen',
  'profile.work-preference.preferredSalary': 'Wunschvergütung pro Stunde',
  'profile.work-preference.preferredWorkingPlaces': 'bevorzugte Einsatzorte',
  'profile.work-preference.preferredWorkingTimes': 'bevorzugte Einsatzzeiten',

  'profileDocument.add_business_license': 'Gewerbeschein hinzufügen',
  'profileDocument.add_enrollment_certificate':
    'Studienbescheinigung hinzufügen',
  'profileDocument.attachmentsToKeep': 'Hochgeladene Dateien',
  'profileDocument.fileInput': 'Dateien / Anhänge hinzufügen',
  'profileDocument.notes': 'Notiz',
  'profileDocument.other_document': 'Sonstige Dokument',
  'profileDocument.please_select': 'BITTE AUSWÄHLEN',
  'profileDocument.remove_business_license': 'Gewerbeschein löschen',
  'profileDocument.remove_enrollment_certificate':
    'Studienbescheinigung löschen',
  'profileDocument.remove_enrollment_license': 'Studienbescheinigung löschen',
  'profileDocument.save_success': 'Dokumente gespeichert',
  'profileDocument.save_success_with_uploads_failed':
    'Dokumente gespeichert trotz fehlerhaften Datei Uploads',
  'profileDocument.type': 'Typ',
  'profileDocument.validFrom': 'Gültig von',

  'profileDocument.validOpenEnded': 'unbegrenzt gültig',
  'profileDocument.validUntil': 'Gültig bis',
  'profileDocument.validity': 'Gültigkeit',

  'profileForm.multiple-element-text.activity-skills': 'Fähigkeit',
  'profileForm.multiple-element-text.address': 'Adresse',
  'profileForm.multiple-element-text.data': 'Daten',
  'profileForm.multiple-element-text.experience': 'Erfahrung',
  'profileForm.multiple-element-text.language': 'Sprache',
  'profileForm.multiple-element-text.profile_document': 'Dokument',
  'profileForm.multiple-element-text.schooling': 'Schul-/ Berufsausbildung',
  'profileForm.multiple-element-text.skill': 'Skill',
  'profileForm.multiple-element-text.social-media': 'Social Media',
  'profileForm.sticky_element_text':
    'Vergiss das Speichern bei Änderungen nicht!',
  'profileForm.sticky_element_title': 'Daten geändert?',
  'profileForm.title.activity-skills': 'Fähigkeiten / Kompetenzen',
  'profileForm.title.addresses': 'Adressen',
  'profileForm.title.appearance': 'Erscheinungsbild',
  'profileForm.title.bank-data': 'Bankdaten',
  'profileForm.title.communication-data': 'Kommunikationsdaten',
  'profileForm.title.images': 'Bilder',
  'profileForm.title.job-employment': 'Angaben zum beruflichen Status',
  'profileForm.title.languages': 'Sprachen',
  'profileForm.title.mobility': 'Mobilität',
  'profileForm.title.personal-data': 'Persönliche Daten',
  'profileForm.title.profile-documents-business-license': 'Gewerbeschein',
  'profileForm.title.profile-documents-enrollment-certificate':
    'Studienbescheinigung',
  'profileForm.title.profile-documents-misc': 'Sonstige Dokumente',
  'profileForm.title.promotion-experiences': 'Aktionserfahrung',
  'profileForm.title.schooling': 'Schul- & Berufsausbildung',
  'profileForm.title.skills': 'Skills / Fähigkeiten',
  'profileForm.title.social-profiles': 'Soziale Medien',
  'profileForm.title.tax': 'Bürokratische Angaben / Steuern',
  'profileForm.title.work-experiences': 'Berufserfahrung',
  'profileForm.title.work-preference': 'Arbeitsvorlieben',

  'profile_document_type.bfa_befreiung': 'BfA-Befreiung',
  'profile_document_type.business_license': 'Gewerbeschein',
  'profile_document_type.certificate': 'Zeugnis, Zertifikat',
  'profile_document_type.criminal_record': 'polizeiliches Führungszeugnis',
  'profile_document_type.cv': 'Lebenslauf',
  'profile_document_type.driving_license': 'Führerschein Kopie',
  'profile_document_type.enrollment_certificate': 'Studienbescheinigung',
  'profile_document_type.health_certificate': 'Gesundheitszeugnis',
  'profile_document_type.identification_card': 'Personalauswes, Reisepass',
  'profile_document_type.misc': 'Sonstiges',
  'profile_document_type.tax_card': 'Lohnsteuerkarte',
  'profile_document_type.tax_confirmation': 'Steuerbescheid/Nachweis Finanzamt',
  'profile_document_type.work_permit': 'Arbeitserlaubnis',

  'profile_image.action_add': '{image_type} hinzufügen',
  'profile_image.action_resize':
    'Das Bild ist zu groß und muss in der Größe geändert werden',
  'profile_image.action_upload': '{image_type} hochladen',
  'profile_image.button.delete': 'Löschen',
  'profile_image.title.misc': 'Weitere Fotos',
  'profile_image.type.american_portrait': 'Amerikanisches Portrait',
  'profile_image.type.full_body': 'Ganzkörperfoto',
  'profile_image.type.misc': 'Misc-Foto',
  'profile_image.type.portrait': 'Portrait',

  'promotionbasis.title': 'Promotionbasis',

  'rating.error_giving_star':
    'Dein Konto muss aktiviert sein und du musst mindestens 5 Bewerbungen abgegeben haben, um diesen Jobanbieter zu bewerten.',
  'rating.error_giving_star_already_rated':
    'Du hast diesen Jobanbieter bereits bewertet.',
  'rating.error_removing_star':
    'Fehler beim Entfernen der Bewertung! Bitte aktualisiere die Seite und versuchen es erneut.',
  'rating.removed': 'Die Bewertung für den Jobanbieter wurde entfernt.',
  'rating.star': 'Star',
  'rating.success':
    'Klasse! Die Bewertung für den Jobanbieter wurde gespeichert.',
  'rating.title': 'Deine favorisierten Jobanbieter',
  'rating.unstar': 'Unstar',
  'rating.write_rating': 'Bewertung abgeben',

  'register_and_job_apply.application': 'Dein Bewerbungstext',
  'register_and_job_apply.city': 'Stadt',
  'register_and_job_apply.continue': 'Weiter',
  'register_and_job_apply.country': 'Land',
  'register_and_job_apply.data': 'Deine Kontaktdaten',
  'register_and_job_apply.email': 'E-Mail Adresse',
  'register_and_job_apply.notes': 'Bewerbungstext / Anschreiben',
  'register_and_job_apply.phone_number': 'Mobilnummer',
  'register_and_job_apply.street': 'Strasse',
  'register_and_job_apply.success':
    'Die Bewerbung wurde gespeichert und dein Account erstellt. Du bekommst nun eine E-Mail mit einem Bestätigungslink. Wichtig: Erst nach der Bestätigung deiner E-Mail-Adresse wird deine Bewerbung an den Jobanbieter weitergeleitet!',
  'register_and_job_apply.zip_code': 'PLZ',

  'routes.myApplications': 'Meine Bewerbungen',
  'routes.myDashboard': 'Mein Dashboard',
  'routes.myData': 'Meine Daten bearbeiten',
  'routes.myDocuments': 'Meine Dokumente verwalten',
  'routes.myFavorites': 'Meine Favoriten',
  'routes.myImages': 'Meine Bilder verwalten',

  'sedcard.download': 'Sedcard herunterladen',
  'sedcard.download_detailed_logo_layout': 'Sedcard-Detailversion',
  'sedcard.download_logo_layout': 'Sedcard-Kurzversion',

  'seoContent.link-title': 'Weiterführende Links',

  'share_box.title': '{element_title} TEILEN',

  'size_of_shirt.l': 'L',
  'size_of_shirt.m': 'M',
  'size_of_shirt.s': 'S',
  'size_of_shirt.xl': 'XL',
  'size_of_shirt.xs': 'XS',
  'size_of_shirt.xxl': 'XXL',

  'skill.computer': 'computer',
  'skill.social': 'social',

  'skill_level.advanced':
    'Fortgeschritten (2-3 Jahre, verschiedene Einsätze/Aktionen)',
  'skill_level.beginner': 'Einsteiger (bis 1 Jahr, wenige Einsätze/Aktionen)',
  'skill_level.expert': 'Experte (> 3 Jahre, viele Einsätze/Aktionen)',

  'social_profile_type.apple': 'Apple',
  'social_profile_type.facebook': 'Facebook',
  'social_profile_type.google': 'Google',
  'social_profile_type.instagram': 'Instagram',
  'social_profile_type.linkedin': 'Linkedin',
  'social_profile_type.microsoft': 'Microsoft',
  'social_profile_type.misc': 'Sonstige',
  'social_profile_type.snapchat': 'SnapChat',
  'social_profile_type.tiktok': 'TikTok',
  'social_profile_type.twitter': 'Twitter',
  'social_profile_type.xing': 'Xing',

  'tooltip.profileForm.addresses': 'Addresses',
  'tooltip.profileForm.appearance': 'Appearance',
  'tooltip.profileForm.bank_data':
    '<strong>Wichtig: </strong>Die Eingabe von Bankdaten ist optional!',
  'tooltip.profileForm.communication_data':
    '<strong>Wichtig: </strong>Die E-Mail-Adresse für den Login änderst Du unter "Mein Account -> E-Mail ändern"',
  'tooltip.profileForm.languages': 'Languages',
  'tooltip.profileForm.mobility': 'Mobility',
  'tooltip.profileForm.personal_data':
    'Personal Data mit Text. <br/>Und sogar mit <strong>HTML!</strong>.',
  'tooltip.profileForm.promotion_experiences': 'Promotion Experiences',
  'tooltip.profileForm.schooling': 'Schooling',
  'tooltip.profileForm.skills': 'Skills',
  'tooltip.profileForm.tax': 'Tax',
  'tooltip.profileForm.work_experiences': 'Work Experiences',
  'tooltip.profileForm.work_preference': 'Work Preference',
  'tooptip.profileForm.social_profiles': 'Social Profiles',

  'validation.mime_type.image': 'Datei muss ein Bild sein',

  'videos.alternative_text_default': 'Vorschaubild des Videos',
  'videos.deleted': 'Video wurde gelöscht',
  'videos.disallowed': 'Du hast die Kamera für diese Seite nicht zugelassen.',
  'videos.goals': 'Deine Ziele',
  'videos.introduction': 'Stelle dich vor',
  'videos.recording_finished':
    'Die Videoaufnahme ist beendet. Das Video wird nun hochgeladen und gespeichert.',
  'videos.recording_uploaded':
    'Das Video wurde erfolgreich hochgeladen und gespeichert!',
  'videos.select_device': 'Gerät auswählen',
  'videos.selling_point': 'Vermarkte dich',

  'wiki.related_jobs': 'Verknüpfte Jobs',
  'wiki.related_wikis': 'Verknüpfte Wiki',
  'wiki.show_more': 'Mehr lesen',

  'working_place.abroad': 'Auslandseinsatz',
  'working_place.home': 'Ortseinsatz',
  'working_place.nationwide': 'Bundesweit',
  'working_place.regional_home': 'Regionaleinsatz / tägliche Rückkehr',
  'working_place.regional_sleep': 'Regionaleinsatz / inkl. Übernachtungen',

  'working_time.always': 'Immer',
  'working_time.arrangement': 'nach Absprache',
  'working_time.day': 'Tagsüber',
  'working_time.evening': 'Abends',
  'working_time.holidays': 'Ferien / Semesterferien',
  'working_time.weekdays': 'Wochentags',
  'working_time.weekend': 'Am Wochenende',
};
